export default ({ app: { router }, $gtag, store }, inject) => {
  let gtagEnabled = false;
  let hotjarEnabled = false;
  let linkedinEnabled = false;

  /**
   * Gtag
   */
  const gtagIds = ['GTM-NDQBZSH'];
  function gtagPageView(route) {
    gtagIds.forEach((gtagId) => {
      $gtag('config', gtagId, {
        page_path: route.fullPath,
        location_path: window.location.origin + route.fullPath,
      });
    });
  }
  function gtagEvent(...args) {
    $gtag(...args);
  }
  router.afterEach((to) => {
    if (gtagEnabled && store.state.enableTracking) gtagPageView(to);
  });

  /**
   * Hotjar
   */
  function enableHotjar() {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 3106751, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }

  /**
   * LinkedIn Insight Tag
   */
  function enableLinkedinInsightTag() {
    // eslint-disable-next-line camelcase
    const _linkedin_partner_id = '3419578';
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);

    (function () {
      const s = document.getElementsByTagName('script')[0];
      const b = document.createElement('script');
      b.type = 'text/javascript';
      b.async = true;
      b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
      s.parentNode.insertBefore(b, s);
    })();
  }

  inject('trackingUpdate', ({ analytics, advertising }) => {
    if (!store.state.enableTracking) return;

    if (!gtagEnabled && analytics) {
      gtagPageView(router.currentRoute);
    }
    gtagEnabled = analytics;

    if (advertising) {
      if (!hotjarEnabled) {
        hotjarEnabled = true;
        enableHotjar();
      }

      if (!linkedinEnabled) {
        linkedinEnabled = true;
        enableLinkedinInsightTag();
      }
    }
  });

  inject('trackingEvent', (...args) => {
    if (!gtagEnabled || !store.state.enableTracking) {
      return;
    }

    gtagEvent(...args);
  });
};
